import React, { MouseEventHandler } from "react";
import LinkButton from "../LinkButton";
import styled from "styled-components";

const Link = styled(LinkButton)`
    font-size: ${(16/12.6).toFixed(2)}rem;
`;

const Icon = styled.i`
    cursor: pointer;
    font-size: 1.75rem;
    margin-right: 0.5em;
    vertical-align: middle;

    body.no-nav & { display: none !important; }
`;

interface Props {
    backTo?: string,
    children?: React.ReactNode,
    label?: string,
    onBackClick?: MouseEventHandler<HTMLElement>,
    onLabelClick?: MouseEventHandler<HTMLElement>
}

function BackButton(props:Props) {
    return <div>
        <Link to={props.backTo} onClick={props.onBackClick}><Icon className="fal fa-arrow-left"></Icon></Link>
        {(props.label || props.children) && <Link onClick={props.onLabelClick}>{props.label || props.children}</Link>}
    </div>
}

export default BackButton;