import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import './index.css';
import reducers from './reducers';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import CMX from './api';
import { checkoutMiddleware } from './middleware/checkout';
import { authMiddleware } from './middleware/auth';
import { ieMiddleware } from './middleware/ie';
import userMiddleware from './middleware/user';
import { analyticsMiddleware } from './middleware/analytics';
import { landingsMiddleware } from './middleware/landings';
import { commonMiddleware } from './middleware/common';

const getPersistedState = () => {
  try {
    const serialized = localStorage.getItem('cmx-state');

    if (!serialized) {
      return undefined;
    }

    return JSON.parse(serialized);
  } catch (e) {
    return undefined;
  }
};

let middleware;
const appliedMidleware = applyMiddleware(thunk, analyticsMiddleware, checkoutMiddleware, authMiddleware, ieMiddleware, userMiddleware, landingsMiddleware, commonMiddleware);
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  middleware = compose(
      appliedMidleware,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  middleware = compose(
      appliedMidleware
  );
}

const store = createStore(
    reducers,
    getPersistedState(),
    middleware
);

store.subscribe(throttle(() => {
  const state = store.getState();
  localStorage.setItem('cmx-state', JSON.stringify({
    user: Object.assign({}, state.user, {coupons: {}, isProcessing: {}, ieCardData: null}),
    notifications: state.notifications,
  }));
}, 1000));

CMX.setReduxStore(store);

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
);

if ('#no-nav' === document.location.hash) {
  document.body.classList.add('no-nav');
}

//registerServiceWorker();
try {
  navigator.serviceWorker.register('https://cinemex.com/service-worker.js').then(registration => {
    console.log('sw unreg');
    registration.unregister();
  }).catch(e => console.log(e));
} catch (e) { console.log('sw error'); }