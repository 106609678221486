import React from 'react';
import {connect} from 'react-redux';
import MDSpinner from "react-md-spinner";
import {Helmet} from 'react-helmet';
import {ieBenefitsFetch} from '../actions/creators/ie';
import {dialogAlertEasyClose} from '../actions/creators/dialogs';
import {isIeBenefitsLoading,getIeBenefitsByCategory, getUserInfo} from '../reducers';
import { asset } from '../utils/misc';
import styled from 'styled-components';
import { breakpoints } from '../utils/styles';
import Button from './Button';

const Tabs = styled.ul`
  & > .nav-item:first-child { margin-left: 0; }
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Benefit = styled.div`
  cursor: pointer;
  margin-bottom: 4%;
  width: 48%;

  @media screen and (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 3%;
    width: 31%;
  }

  & > img {
    width: 100%;
  }
`;

class IELevelsBenefitsPage extends React.Component {
  constructor(props) {
    super(props);

    this.onTabChange = this.onTabChange.bind(this);

    this.state = {
      currentTab: 'one',
    };
  }

  onTabChange(e) {
    e.preventDefault();
    const currentTab = e.target.getAttribute('data-target');

    this.setState(() => ({currentTab}));
  }

  componentWillMount() {
    this.props.fetch();
  }

  render() {
    return (
        <React.Fragment>
          <Helmet><title>Niveles y Beneficios - Cinemex Loop</title></Helmet>

          <p className="lead">Forma parte del mejor programa de recompensas que Premia tu Diversión, adquiere tu
            tarjeta y empieza a disfrutar de los beneficios de ser miembro Cinemex Loop.</p>

          {this.renderTabs()}

          <div className="tab-content">
            {this.renderContents()}
          </div>

          {this.renderSignUpButton()}

          <p style={{lineHeight:1.2}}>
            <small>* A partir del 2024, todos los invitados que lleguen a las visitas requeridas para subir de
              nivel podrán adelantar su cambio durante el transcurso del año de acuerdo con las visitas
              registradas con las que cuente el invitado. Las visitas tienen vigencia de un año y empiezan su
              contador en cero el 1ro de enero de cada año. Nivel One para subir a Red requiere 4 visitas,
              Red a Gold - 12 visitas, Gold a Platino - 24 visitas.
            </small>
          </p>
        </React.Fragment>
    );
  }

  renderTabs() {
    return (
        <Tabs className="nav nav-pills mb-5" id="pills-tab" role="tablist">
          <li className="nav-item">
            <a onClick={this.onTabChange} data-target="one" href="#" className={'nav-link ' + (this.state.currentTab === 'one' ? 'active' : '')} id="pills-home-tab" role="tab"
              aria-controls="pills-basic" aria-selected="true">One</a>
          </li>
          <li className="nav-item">
            <a onClick={this.onTabChange} data-target="basic" href="#" className={'nav-link ' + (this.state.currentTab === 'basic' ? 'active' : '')} id="pills-home-tab" role="tab"
               aria-controls="pills-basic" aria-selected="true">Red</a>
          </li>
          <li className="nav-item">
            <a onClick={this.onTabChange} data-target="gold" href="#" className={'nav-link ' + (this.state.currentTab === 'gold' ? 'active' : '')} id="pills-profile-tab" role="tab"
               aria-controls="pills-gold" aria-selected="false">Gold</a>
          </li>
          <li className="nav-item">
            <a onClick={this.onTabChange} data-target="premium" href="#" className={'nav-link ' + (this.state.currentTab === 'premium' ? 'active' : '')} id="pills-contact-tab" role="tab"
               aria-controls="pills-premium" aria-selected="false">Platino</a>
          </li>
        </Tabs>
    );
  }

  renderContents() {
    switch (this.state.currentTab) {
      case 'one':
        return this.renderContentsOne();
      case 'basic':
        return this.renderContentsBasic();
      case 'gold':
        return this.renderContentsGold();
      case 'premium':
        return this.renderContentsPremium();
    }
  }

  renderContentsOne() {
    return (
        <div key="one" className="tab-pane fade-in show active" role="tabpanel" aria-labelledby="pills-one-tab">
          <div className="row mb-5">
            <div className='col-6 col-md-3'>
              <img src={asset('/dist/images/ie/card-one.png')} className="img-fluid shadow mr-5" alt=""/>
            </div>
            <div className='col-6 col-md-9'>
              <strong className="text-primary">Nivel One:</strong> Para obtener este nivel puedes inscribirte de
              forma gratuita al programa Cinemex Loop en nuestros medios digitales. <strong>Acumulación del 3%</strong>
            </div>
          </div>

          {this.renderBenefits('one')}
        </div>
    );
  }

  renderContentsBasic() {
    return (
        <div key="basic" className="tab-pane fade-in show active" role="tabpanel" aria-labelledby="pills-basic-tab">
          <div className="row mb-5">
            <div className='col-6 col-md-3'>
              <img src={asset('/dist/images/ie/card-basica.png')} className="img-fluid shadow mr-5" alt=""/>
            </div>
            <div className='col-6 col-md-9'>
              <strong className="text-primary">Nivel Red:</strong> Para obtener este nivel puedes inscribirte
              al programa Cinemex Loop en nuestros medios digitales o en un Centro de Atención al Invitado. <strong>Acumulación
              del 5%</strong>
            </div>
          </div>

          {this.renderBenefits('basic')}
        </div>
    );
  }

  renderContentsGold() {
    return (
        <div key="gold" className="tab-pane fade-in show active" role="tabpanel" aria-labelledby="pills-gold-tab">

          <div className="row mb-5">
            <div className='col-6 col-md-3'>
              <img src={asset('/dist/images/ie/card-oro.png')} className="img-fluid shadow mr-5" alt=""/>
            </div>
            <div className='col-6 col-md-9'>
              <strong className="text-primary">Nivel Gold:</strong> Para formar parte de este selecto grupo de
              miembros Cinemex Loop, deberás acumular 12 visitas* en Cinemex en el transcurso del año calendario. <strong>Acumulación
              del 8%</strong>
            </div>
          </div>

          {this.renderBenefits('gold')}
        </div>
    );
  }

  renderContentsPremium() {
    return (
        <div key="premium" className="tab-pane fade-in show active" role="tabpanel" aria-labelledby="pills-premium-tab">

          <div className="row mb-5">
            <div className='col-6 col-md-3'>
              <img src={asset('/dist/images/ie/card-premium.png')} className="img-fluid shadow mr-5" alt=""/>
            </div>
            <div className='col-6 col-md-9'>
              <strong className="text-primary">Nivel Platino:</strong> Para formar parte de este exclusivo grupo de
              miembros Cinemex Loop, deberás acumular 24 visitas* en Cinemex en el transcurso del año calendario.
              <strong>Acumulación del 10%</strong>
            </div>
          </div>

          {this.renderBenefits('premium')}
        </div>
    );
  }

  renderBenefits(category) {
    if (this.props.isLoading) {
      return <div className="p-5 text-center"><MDSpinner singleColor="white"/></div>;
    }

    if (!this.props.benefits[category]) {
      return '';
    }

    return <BenefitsContainer>
      {this.props.benefits[category].map(benefit => (
        <Benefit key={benefit.id} data-content={benefit.description} onClick={() => this.props.dialogOpen(benefit.description)}>
          <img src={benefit.thumb} alt=""/>
        </Benefit>
      ))}
    </BenefitsContainer>;
  }

  renderSignUpButton() {
    if (this.props.userInfo && this.props.userInfo.iecode) return null;
    
    return <div className="my-5">
      <Button to="/usuario/loop/redireccion" primary>Inscríbete aquí</Button>
    </div>;
  }
}

const mapStateToProps = state => ({
  isLoading: isIeBenefitsLoading(state),
  benefits: getIeBenefitsByCategory(state),
  userInfo: getUserInfo(state),
});

const mapDispatchToProps = dispatch => ({
  fetch: () => dispatch(ieBenefitsFetch()),
  dialogOpen: message => dispatch(dialogAlertEasyClose(message)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IELevelsBenefitsPage);