import React from 'react';
import styled from 'styled-components';
import TinySlider from 'tiny-slider-react';
import uniqid from 'uniqid';
import SliderArrows from "../SliderArrows";
import SliderContainer from '../SliderContainer';

const CategoryCard = styled.div`
  cursor: pointer;
  
  &.selected { background: var(--primary); }
`;

const getCategoryItemsCountString = category => {
  const count = category.items.length;

  return count + ' producto' + (count !== 1 ? 's': '');
};

let navId = '';

class CandybarCategories extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);

    navId = uniqid();
  }

  onClick(slide, info, event) {
    let target = event.target;

    while (!target.classList.contains('c-slider__item')) {
      target = target.parentNode;
    }

    const categoryId = parseInt(target.getAttribute('data-category-id'), 10);

    let sibling = target.parentNode.firstChild;
    do {
      if (sibling.nodeType !== 1 || sibling === target) {
        continue;
      }

      sibling.children[0].classList.remove('selected');
      sibling = sibling.nextSibling;
    } while (sibling);

    target.children[0].classList.add('selected');

    this.props.onSelect(categoryId);
  }

  render() {
    const settings = {
      controlsContainer: '#' + navId,
      items: 1.25,
      gutter: 15,
      mouseDrag: true,
      nav: false,
      responsive: {
        '1200': {items: 4.25},
        '992': {items: 3.25},
        '480': {items: 2.5},
      }
    };

    return (
        <SliderContainer>
          <TinySlider settings={settings} onClick={this.onClick} className="c-slider c-slider--preview-small">
            {this.props.categories.map(category => (
                <div className="c-slider__item" key={category.id} data-category-id={category.id} >
                  <CategoryCard className="c-card-icon">
                    {category.icon && <span className="c-card-icon__icon"><img src={category.icon} alt="" /></span>}
                    <h3 className="c-card-icon__title">{category.name}</h3>
                    <p><small>{getCategoryItemsCountString(category)}</small></p>
                  </CategoryCard>
                </div>
            ))}
          </TinySlider>
          <SliderArrows navId={navId}/>
        </SliderContainer>
    );
  }

}

export default  CandybarCategories;