import React from "react";
import { Link } from 'react-router-dom';
import styled, { css } from "styled-components";
import { breakpoints } from '../utils/styles';
import { hasIeAction, onAvatarLoadError } from "../utils/misc";
import IconHome from '../assets/svg/nav-icon--home.svg';
import IconCoupons from '../assets/svg/nav-icon--coupons.svg';
import IconLoop from '../assets/svg/nav-icon--loop.svg';
import IconTickets from '../assets/svg/nav-icon--tickets.svg';
import IconUser from '../assets/svg/nav-icon--user.svg';

const FooterNav = styled.nav`
  background: var(--page-bg);
  box-shadow: 0 0 10px rgba(0,0,0,0.25);
  bottom: 0;
  font-size: 0.7rem;
  left: 0;
  line-height: 1.2;
  padding: 0.85rem 0.4rem 0.65rem;
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 1;

  & > ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  ${() => `@media screen and (min-width: ${breakpoints.desktop}px) {
    display: none;
  }`}

  body.theme-market & {
    box-shadow: 0 0 10px rgba(255,255,255,0.25);
  }
  body.theme-platinum & {
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
  }

  body.no-nav & { display: none; }
`;

const FooterNavPic = css`
    display: block;
    margin: 0 auto 0.25rem;

    body.theme-market & { margin-bottom: 0.75rem; }
`;

const FooterNavIcon = styled.i`
    ${FooterNavPic}
    font-size: 1.75rem;
`;

const FooterNavImage = styled.img`
    ${FooterNavPic}
    height: 1.75rem;
    border-radius: 100%;
`;

const FooterNavSvg = styled.div`
    ${FooterNavPic}
    background: var(--text-primary);
    height: 1.75rem;
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: 100%;
    width: 1.75rem;
`;

const FooterNavItem = styled.li`
  position: relative;

  & > a {
    color: ${props => props.active ? 'var(--primary);' : 'var(--text-color);'};
  }
  & > a > ${FooterNavSvg} {
    background: ${props => props.active ? 'var(--primary);' : 'var(--text-primary);'};
  }

  ${props => props.hasNotifications ? `
    &:before {
        background: var(--primary);
        border-radius: 12px;
        content: "";
        display: block;
        height: 12px;
        right: 25%;
        position: absolute;
        top: -4px;
        width: 12px;
    }
  ` : ''}

  body.theme-market & {
    font-size: 0.9em;
    text-transform: uppercase;
  }
`;

class FooterMobileNav extends React.Component {
    constructor(props) {
        super(props);
        this.onAuthClick = this.onAuthClick.bind(this);
        this.onCinemasClick = this.onCinemasClick.bind(this);
    }

    onAuthClick(e) {
        e.preventDefault();
        this.props.openAuthPanel();
    }

    onCinemasClick(e) {
        e.preventDefault();

        this.props.openCinemaSelector();
    }

    render() {
        const couponsActive = this.props.path === '/usuario/cupones';

        return <FooterNav>
            <ul>
                {this.renderHomeLink()}
                <FooterNavItem active={couponsActive}><Link to="/usuario/cupones"><FooterNavSvg style={{maskImage: `url(${IconCoupons}`}}></FooterNavSvg> Cupones</Link></FooterNavItem>
                {this.renderIeLink()}
                {this.renderTicketsLink()}
                {this.renderUserLink()}
            </ul>
        </FooterNav>;
    }

    renderHomeLink() {
        const active = this.props.path === '/' || this.props.path.indexOf('/pelicula/') === 0;

        return <FooterNavItem active={active}>
            <Link to="/">
            <FooterNavSvg style={{maskImage: `url(${IconHome}`}}></FooterNavSvg>
                Cartelera
            </Link>
        </FooterNavItem>;
    }

    renderTicketsLink() {
        const path = '/usuario/mis-compras/tickets-activos'
        const active = this.props.path.indexOf(path) === 0;
        const notification = this.props.purchases.length > 0;

        return <FooterNavItem active={active} hasNotifications={notification}>
            <Link to={path}><FooterNavSvg style={{maskImage: `url(${IconTickets}`}}></FooterNavSvg> Boletos</Link>
        </FooterNavItem>;
    }

    renderIeLink() {
        const { user } = this.props;
        const currentPath = this.props.path;
        let active, path;

        if (!user) {
            path = '/loop/programa';
            active = currentPath.indexOf('/loop/') === 0;
        } else if (user.iecode) {
            path = '/usuario/loop/tarjeta';
            active = currentPath.indexOf(path) === 0;
        } else if (hasIeAction(user, 'sign-up')) {
            path = '/usuario/loop/inscripcion';
            active = currentPath.indexOf('/usuario/loop/') === 0;
        } else {
            path = '/usuario';
            active = false; //currentPath.indexOf(path) === 0;
        }

        return <FooterNavItem active={active}><Link to={path}>
            <FooterNavSvg style={{maskImage: `url(${IconLoop}`}}></FooterNavSvg>
            Loop
        </Link></FooterNavItem>;
    }

    renderUserLink() {
        const { user } = this.props;
        const currentPath = this.props.path;
        let active, path, onClick;

        if (!user) {
            path = '/usuario';
            active = false;
            onClick = this.onAuthClick;
        } else {
            path = '/usuario';
            active = currentPath === path; //currentPath.indexOf(path) === 0 && currentPath.indexOf('/invitado-especial') === -1;
            onClick = null;
        }

        return <FooterNavItem active={active}><Link to={path} onClick={onClick}>
            {user ? <FooterNavImage src={user.avatar} alt="Foto de perfil" onError={onAvatarLoadError} /> : <FooterNavSvg style={{maskImage: `url(${IconUser}`}}></FooterNavSvg>}
            Perfil
        </Link></FooterNavItem>;
    }
}

export default FooterMobileNav;